import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import {RootState} from '../store';
import {  List, Row, Space, Typography } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import { useIntl } from 'react-intl';
import ErrorPage from '../ErrorPage'

interface CertActionVariables {
  IDENTITY_ID: string;
  IDENTITY_GROUP_ID: string;
  CERT_TEMPLATE_ID: string;
  CERT_ID: string;
  NETWORKS: string;
}

export function CertAction(props: { data: CertActionVariables }) {
  const { data } = props
  const { $t } = useIntl()
  const { Title, Text, Link } = Typography
  const auth = useSelector((state: RootState) => state.auth);
  const [selectedSsid, setSelectedSsid] = useState<string>('')
  const networkList = data.NETWORKS.split(',')
  const downloadCertUrl = new URL(`/deviceEnrollments/workflows/${auth.workflowId}/certificates?auth=${auth.token}`, location.origin).href

  useEffect(() => {
    if (networkList.length === 1) {
      setSelectedSsid(networkList[0])
    }
  }, [])

  if (networkList.length === 0 || networkList[0] === '') {
    return (<ErrorPage message={$t({ defaultMessage: 'No networks available.' })} />)
  }

  return (
    <Space direction='vertical' size='large' align='center'>
      <Title level={2} >
        {$t({ defaultMessage: 'Connect to the network' })}
      </Title>

      {(networkList.length > 1 && selectedSsid === '') &&
        <Text strong>
          {$t({ defaultMessage: 'Install certificate in order to connect to the following networks:' })}
        </Text>
      }

      <List bordered
        dataSource={selectedSsid ? [selectedSsid] : networkList}
        renderItem={
          (ssid, index) => (
            (ssid && ssid.trim().length > 0) &&

            <List.Item>
              <Row style={{ width: '100%' }}>

                {(networkList.length > 1) ?
                  <Link data-testid={`lnw-${index}`}
                    onClick={() => setSelectedSsid(selectedSsid ? '' : ssid)}
                    strong={true} >
                    <Text strong={true}><b>{ssid}</b><br /></Text>
                  </Link>
                  : <Text strong={true}><b>{selectedSsid}</b><br /></Text>
                }

              </Row>
            </List.Item>

          )} />
      <Text>
        {$t({ defaultMessage: 'Scan or click to download the certificate' })}
      </Text>

      <QRCodeSVG data-testid='qr-code'
        value={downloadCertUrl}
        size={180}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={false}
        onClick={() => {
          if (networkList.length > 1) {
            setSelectedSsid('');
          }
        }}
      />
      <Link href={downloadCertUrl} target='_blank'
        strong={true} >
        <Text strong={true}><b>{$t({ defaultMessage: 'Download Certificate' })}</b><br /></Text>
      </Link>

    </Space>
  )
}
